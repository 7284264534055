import request from '@/utils/request'

/**
 * 查询服务商注册入驻接口
 * @param params
 * @returns {*}
 */
export function getfind_register (params) {
  return request({
    url: 'find_register',
    method: 'get',
    params
  })
}

/**
 * 查询服务商注册入驻信息接口
 * @param params
 * @returns {*}
 */
export function query_register (params) {
  return request({
    url: 'query_register',
    method: 'get',
    params
  })
}

/**
 * 服务商注册
 * @param data
 * @returns {*}
 */
export function serviceregister (data) {
  return request({
    url: 'register',
    method: 'post',
    data
  })
}

/**
 * ocr营业执照识别
 * @param data
 * @returns {*}
 */
export function ocr (data) {
  return request({
    url: `/ocr/recognize_car_number`,
    method: 'post',
    data
  })
}


/**
 * ocr身份证识别
 * @param data
 * @returns {*}
 */
export function ocrocrRecognizeIdcard (data) {
  return request({
    url: `common/ocr/recognize_idcard`,
    method: 'post',
    data
  })
}

/**
 * 提交
 * @param data
 * @returns {*}
 */
export function chuangjiang (data) {
  return request({
    url: `/facilitator/create`,
    method: 'post',
    data
  })
}
