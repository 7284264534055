<template>
  <!--服务商列表-->
  <div style="padding: 20px">
    <div
      v-if="
        showToexAmine == false &&
        showEdit == false &&
        showDetails == false &&
        addshow == false
      "
    >
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="企业名称:">
                    <el-input
                      clearable
                      v-model="where.company_name"
                      placeholder="请输入企业名称"
                    />
                  </el-form-item>
                </el-col>
                <!-- <el-col :lg="6" :md="12">
                  <el-form-item label="企业状态:">
                    <el-select
                      clearable
                      v-model="where.approve_status"
                      placeholder="请选择状态"
                      class="ele-fluid"
                    >
                      <el-option
                        v-for="item in dict.type.enterprise_status"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col> -->
                <el-col :lg="6" :md="12">
                  <el-form-item label="启用状态:">
                    <el-select
                      clearable
                      v-model="where.status"
                      placeholder="请选择状态"
                      class="ele-fluid"
                    >
                      <el-option
                        v-for="item in dict.type.enable_status"
                        :label="item.label"
                        :value="item.value"
                        :key="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="认证状态:">
                    <el-select
                      clearable
                      v-model="where.approve_status"
                      placeholder="请选择状态"
                      class="ele-fluid"
                    >
                      <el-option label="未通过" :value="-1"></el-option>
                      <el-option label="待认证" :value="0"></el-option>
                      <el-option label="认证通过" :value="1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text mr-min">数据列表</div>
              <el-button type="warning" @click="found" v-auths="[`${$config.uniquePrefix}yunli:serviceProviderList:save`]">创建服务商</el-button>
            </div>
            <div>
              <el-button @click="ListSetup()">列表设置</el-button>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"

            >
              <!--自定义列显示 示例-->
              <template v-slot:status="{ scope }">
                <div>
                  <img
                    style="cursor: pointer"
                    src="@/assets/images/home/yiqiyong.png"
                    alt=""
                    v-if="scope.row.status == 0"
                  />
                  <img
                    style="cursor: pointer"
                    src="@/assets/images/home/weiqiyong.png"
                    alt=""
                    v-else
                  />
                </div>
              </template>

              <template v-slot:registration_place_name="{ scope }">
                <span
                  v-for="item in scope.row.registration_place_name"
                  style="margin-right: 10px"
                  >{{ item }}</span
                >
              </template>
               <template v-slot:registration_place_label="{ scope }">
                <!--<el-link :underline="false" type="warning" @click="Toexamine(scope.row)">认证审核</el-link>-->
                    <span>{{scope.row.registration_place_label | formattedAddress}}</span>
              </template>
              <!--自定义列显示 示例-->
              <template v-slot:approve_status="{ scope }">
                <div v-if="scope.row.approve_status == 0">
                  <span
                    style="
                      width: 8px;
                      height: 8px;
                      background: #ff9e1c;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span>待认证</span>
                </div>
                <div v-if="scope.row.approve_status == 1">
                  <span
                    style="
                      width: 8px;
                      height: 8px;
                      background: #25eb61;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span>已认证</span>
                </div>
                <div v-if="scope.row.approve_status == -1">
                  <span
                    style="
                      width: 8px;
                      height: 8px;
                      background: red;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span>未通过</span>
                </div>
              </template>

                <template v-slot:is_open_coop="{ scope }">
                <div v-if="scope.row.is_open_coop == 0">
                  <span
                    style="
                      width: 8px;
                      height: 8px;
                      background: #ff9e1c;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span>已开放</span>
                </div>

                <div v-if="scope.row.is_open_coop == 1">
                  <span
                    style="
                      width: 8px;
                      height: 8px;
                      background: red;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span>未开放</span>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot="{ scope }">
                <!--<el-link :underline="false" type="warning" @click="Toexamine(scope.row)">认证审核</el-link>-->
                <el-link
                  :underline="false"
                  type="warning"
                  v-auths="[`${$config.uniquePrefix}yunli:serviceProviderList:info`]"
                  @click="Todetail(scope.row)"
                  >详情</el-link
                >
                <!-- v-if="isDetails == true"
                  v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:info`,
                  ]" -->
                <el-link
                  :underline="false"
                  type="success"
                  @click="reset(scope.row)"
                   v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:put`,
                  ]"
                  >重置密码</el-link
                >
                <el-link
                  :underline="false"
                  type="warning"
                  @click="to_admin(scope.row)"
                   v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:jingru`,
                  ]"
                  >进入后台</el-link
                >
                <!-- <el-link
                  :underline="false"
                  type="warning"
                  @click="openEdit(scope.row)"
                  v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:put`,
                  ]"
                  >编辑</el-link
                > -->

                <el-link
                  slot="reference"
                  :underline="false"
                  type="danger"
                  v-if="scope.row.status == 0"
                   v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:status`,
                  ]"
                  @click="EditStatus('1', scope.row)"
                  >停用</el-link
                >
                <el-link
                  slot="reference"
                  :underline="false"
                  type="danger"
                  v-if="scope.row.status == 1"
                   v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:status`,
                  ]"
                  @click="EditStatus('0', scope.row)"
                  >启用</el-link
                >

                <el-link
                  v-if="scope.row.is_open_coop == 0"
                  :underline="false"
                  type="danger"
                   v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:status`,
                  ]"
                  @click="kaifang(scope.row)"
                  >禁用开放</el-link
                >

                <el-link
                  v-if="scope.row.is_open_coop == 1"
                  :underline="false"
                  type="warning"
                   v-auths="[
                    `${$config.uniquePrefix}yunli:serviceProviderList:status`,
                  ]"
                  @click="kaifang(scope.row)"
                  >启用开放</el-link
                >

                <el-popconfirm
                  title="确定执行此操作吗？"
                  @confirm="del(scope.row)"
                >
                  <!-- <el-link
                    slot="reference"
                    style="margin-left: 10px"
                    :underline="false"
                    type="info"
                    v-auths="[
                      `${$config.uniquePrefix}yunli:serviceProviderList:delete`,
                    ]"
                    >删除</el-link
                  > -->
                </el-popconfirm>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose"
        >
          <span>
            <PackageSortElement
              :AllParameters="columns"
              @change="SortList"
              @show="dialogshow"
            ></PackageSortElement>
          </span>
        </el-dialog>
      </div>
    </div>

    <!--服务商认证审核-->
    <ToExamine
      v-if="showToexAmine == true"
      :data="current"
      @showtoexamine="ShowToexAmine"
      @dome="getList"
    />

    <!--编辑服务商弹窗-->
    <Edit
      v-if="showEdit == true"
      :id="pid"
      :dict="dict"
      @showedit="ShowEdit"
      @done="getList"
    />
    <Add v-if="addshow == true" @adds="addstatus" />
    <!--详情-->
    <Details
      v-if="showDetails == true"
      :id="pid"
      :dict="dict"
      :myMessage="message"
      @showDetalis="ShowDetalis"
    />

    <!--充值面膜-->
    <el-dialog
      center
      v-if="showreset == true"
      title="重置密码"
      :visible.sync="showreset"
      width="30%"
      :before-close="handleCloseshowreset"
    >
      <div>
        <el-input
          v-model="password"
          clearable
          show-password
          placeholder="请填写密码"
        ></el-input>
      </div>
      <div slot="footer">
        <!--<el-button-->
        <!--  @click="dialogVisible = false">取消-->
        <!--</el-button>-->
        <el-button
          style="margin-left: 20px"
          type="primary"
          @click="qiangdansave"
          >确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import { ShowTable } from "@/utils";
//

// 引入的接口
import {
  Delfacilitators,
  Editstatus,
  getfacilitators,
  reset_password,
  to_admin,
  open_coop
} from "@/api/yunli";

//引入添加模块
import Add from "./add/index.vue";
//引入服务商认证审核模块
import ToExamine from "./toExamine/index.vue";
//引入编辑服务商模块
import Edit from "./edit/edit.vue";
//引入详情模块
import Details from "./details/index.vue";
import Vue from "vue";
import TechnicianEdit from "@/views/yunli/edit/edit.vue";
import { setCookies, removeCookies } from "@/utils/cookies";

import VueCookies from "vue-cookies";

export default {
  // 组件生效
  components: {
    TechnicianEdit,
    PackageSortElement,
    ToExamine,
    Edit,
    Details,
    Add,
  },
  data() {
    return {
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        // {
        //   type: "selection",
        //   isShow: true,
        // },
        // {
        //   label: "系统编号",
        //   // type: "index",
        //   // width: "70"
        //   prop: "id",
        //   isShow: true,
        //   fixed: "left",
        // },
        {
          label: "ID",
          prop: "id",
          isShow: true,
        },
        {
          label: "企业名称",
          prop: "company_name",
          isShow: true,
        },
        {
          label: "企业简称",
          prop: "facilitator_abbreviation",
          isShow: true,
        },
        {
          label: "老板账号",
          prop: "admin_account",
          isShow: true,

        },
        //  {
        //   label:"钱包余额",
        //   prop: "operation_money",
        //   slot: "operation_money",
        //   isShow: true,
        // },
        // {
        //   label:"电话分钟剩余",
        //   prop: "phone_minute",
        //   slot: "phone_minute",
        //   isShow: true,
        // },
        // {
        //   label: "短信剩余量",
        //   prop: "note_surplus",
        //   slot: "note_surplus",
        //   isShow: true,
        // },
        {
          label: "注册来源",
          prop: "genre",
          isShow: true,
          width: "120",
        },
        {
          label: "创建时间",
          prop: "create_time",
          isShow: true,
        },
        {
          label: "注册区域",
          // width: "120",
          prop: "registration_place_label",
           slot: "registration_place_label",
          isShow: true,
        },
        // {
        //   label: "所在地区",
        //   prop: "registration_place_name",
        //   slot: "registration_place_name",
        //   width: "180",
        //   isShow: true,
        // },
        // {
        //   label: "会员等级",
        //   prop: "level_name",
        //   isShow: true,
        // },

        {
          label: "启用状态",
          prop: "status",
          slot: "status",
          width: "100",
          // 权限判断
          // auths: ShowTable([`${this.$config.uniquePrefix}custom:set_status`]),
          isShow: true,
        },
        {
          label:'开放合作',
          prop:"is_open_coop",
          slot:"is_open_coop",
          isShow: true,
        },
        {
          label: "认证状态",
          prop: "approve_status",
          slot: "approve_status",
          isShow: true,
        },
        {
          label: "操作",
          width: "300",
          slot: "operationSlot",
          isShow: true,
          fixed: "right",
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,

      //审核组件
      showToexAmine: false,
      current: null,

      //编辑组件
      showEdit: false,

      //详情组件
      showDetails: false,
      message: "0",

      //字典
      dict: {
        type: {
          enable_status: [
            { label: "启用", value: 0 },
            { label: "停用", value: 1 },
          ],
        },
      },
      // dict.type.enable_status
      pid: 0,

      //是否显示详情按钮
      isDetails: true,

      //重置密码
      showreset: false,
      password: "",
      chongzhishujju: null,
      //添加组件
      addshow: false,
    };
  },

  created() {},

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 判断是否显示
    if (localStorage.getItem("identity_rank") == "superman") {
      this.isDetails = false;
    } else {
      this.isDetails = true;
    }

    let show = localStorage.getItem("showDetails");
    console.log(show);
    if (show === "true") {
      this.showDetails = true;
      this.message = "1";
    } else {
      this.showDetails = false;
    }
    localStorage.removeItem("showDetails");

    // 初始化dict
    this.$store.getters.dict.forEach((item) => {
      if (item.name === "运力字典") {
        item.children.forEach((res) => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        });
      }
    });
    console.log(this.dict);

    //调用获取客户信息列表方法
    this.getList();
  },

  // destroyed(){
  //   this.$EventBus.$off('showDetailsxianshi');
  // },
   filters: {
    formattedAddress(a) {
      // 使用正则表达式或其他方法提取省、市、区
      // 注意：这里只是一个简单的示例，实际情况可能需要更复杂的处理
      // console.log(a)
      const parts = a.split('/');
      const province = parts[0];
      const city = parts[1];
      const district = parts[2].split('市')[0] ; // 去除重复的市名

      // 返回格式化后的地址
      return `${province}${city}${district}`;
    }
  },
  methods: {
    addstatus(value) {
      this.addshow = false;
      this.getList();
    },
    // 获取列表
    getList() {
      getfacilitators(this.where)
        .then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },

    // 查询条件
    QueryList() {
      console.log(this.where);
      this.where.page = 1;
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },

    to_admin(row) {
      if(row.approve_status!=1){
        this.$message.error("该服务商未通过认证");
        return false;
      }
      to_admin(row.id).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          // console.log(res.data.token)
          // removeCookies('LuminousToken')
          // console.log(VueCookies);

          // const domain = "https://saas.jiuyuanshifu.cn:26686";
          // const name = "LuminousToken";
          // const value = res.data.token;
          // console.log(value);
          // VueCookies.set(name, value, { domain: '.jiuyuanshifu.cn', path: "/" });
          document.cookie = `LuminousToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${res.data.domain}; path=/`;
          document.cookie = `LuminousToken=${res.data.token}; domain=${res.data.domain}; path=/`;
          window.open(res.data.saas_url);
        }
      });
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList();
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false;
      } else {
        return true;
      }
    },

    // 启用状态
    EditStatus(value, row) {
      console.log(row);
      let that = this;
      this.$utils.confirm(
        "确定执行此操作吗？",
        function () {
          // 执行一些需要的逻辑
          let condition = {
            status: value,
          };
          Editstatus(row.id, condition)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success(res.msg);
                that.getList();
              } else {
                that.$message.success(res.msg);
                that.getList();
              }
            })
            .catch((e) => {
              that.$message.error(e.msg);
            });
        },
        "确定",
        "warning"
      );
    },

    // 删除
    del(row) {
      console.log(row);
      Delfacilitators(row.id)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getList();
          } else {
            this.$message.success(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },

    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose() {
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value) {
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value) {
      this.ListSetupShow = value;
    },

    //点击认证审核
    Toexamine(row) {
      this.current = row;
      //显示审核模块
      this.showToexAmine = true;
    },
    //接收审核子组件传递的值
    ShowToexAmine(value) {
      this.showToexAmine = value;
    },

    //点击编辑按钮
    openEdit(row) {
      this.pid = row.id;
      this.showEdit = true;
    },
    //接收编辑子组件传递的值
    ShowEdit(value) {
      this.showEdit = value;
    },

    //点击详情按钮
    Todetail(row) {
      this.pid = row.id;
      this.showDetails = true;
    },
    //点击创建
    found() {
      // this.pid = null;
      // this.showDetails = true;
      this.addshow = true;
    },
    //接收详情子组件传递的值
    ShowDetalis(value) {
      this.showDetails = value;
      this.getList();
    },

    //点击重置密码
    reset(row) {
      this.chongzhishujju = row;
      this.showreset = true;
    },
    // 点击确定
    qiangdansave() {
      if (this.password == "") {
        this.$message.error("密码不能为空");
      } else {
        console.log("重置密码");
        let data = {
          password: this.password,
        };
        reset_password(this.chongzhishujju.id, data)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.password = "";
              this.showreset = false;
              this.getList();
            } else {
              this.$message.success(res.msg);
            }
          })
          .catch((e) => {
            this.$message.error(e.msg);
          });
      }
    },
    kaifang(row){
       let id = row.id;
       open_coop(id).then((res)=>{
          if (res.code === 200) {
              this.$message.success(res.msg);
              this.getList();
            } else {
              this.$message.success(res.msg);
            }
       })
    },

    //关闭重置密码弹窗
    handleCloseshowreset() {
      this.showreset = false;
      this.password = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.mr-min {
  margin-right: 20px;
}
</style>
