<template>
  <div>
    <div>
      <div class="title">
        <div class="title_t" style="margin-bottom: 0;">
          <span>{{title}}司机</span>
        </div>
        <div class="title_r" @click="returnPage">
          <span>返回</span>
        </div>
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <el-card shadow="never" style="margin-top: 20px;">
          <el-row :gutter="15" style="padding-top: 30px;">
            <!--左侧内容-->
            <el-col :span="8">
              <el-row :gutter="15">
                <el-col :span="12">
                  <div class="upload_bg">
                    <el-upload
                      :action="action"
                      :headers="headers"
                      :on-success="successUpload"
                      :limit="1"
                      :file-list="fileList"
                      :class="{hide_box: upload_btn}"
                      :on-change="change"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传身份证正面</div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="upload_bg_jiashizheng">
                    <el-upload
                      :action="action"
                      :headers="headers"
                      :on-success="successUpload3"
                      :limit="1"
                      :file-list="fileList1"
                      :class="{hide_box: licenseupload_btn}"
                      :on-change="licensechange"
                      list-type="picture-card"
                      :on-preview="licensePreview"
                      :on-remove="licensehandleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传驾驶证正面</div>
                    </el-upload>
                    <el-dialog :visible.sync="licensedialogVisible">
                      <img width="100%" :src="licensedialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="15" style="margin-top: 20px;">
                <el-col :span="12">
                  <div class="upload_bg_shenfenzheng">
                    <el-upload
                      :action="action"
                      :headers="headers"
                      :on-success="successUpload1"
                      :limit="1"
                      :file-list="fileList2"
                      :class="{hide_box: oppositeupload_btn}"
                      :on-change="oppositechange"
                      list-type="picture-card"
                      :on-preview="oppositePreview"
                      :on-remove="oppositehandleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传身份证反面</div>
                    </el-upload>
                    <el-dialog :visible.sync="oppositedialogVisible">
                      <img width="100%" :src="oppositedialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="upload_bg_jiashizhengfanmian">
                    <el-upload
                      :action="action"
                      :headers="headers"
                      :on-success="successUpload4"
                      :limit="1"
                      :file-list="fileList3"
                      :class="{hide_box: licenseoppositeupload_btn}"
                      :on-change="licenseoppositechange"
                      list-type="picture-card"
                      :on-preview="licenseoppositePreview"
                      :on-remove="licenseoppositehandleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传驾驶证反面</div>
                    </el-upload>
                    <el-dialog :visible.sync="licenseoppositedialogVisible">
                      <img width="100%" :src="licenseoppositedialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="15" style="margin-top: 20px;">
                <el-col :span="12">
                  <div class="upload_bg_shouchi">
                    <el-upload
                      :action="action"
                      :headers="headers"
                      :on-success="successUpload2"
                      :limit="1"
                      :file-list="fileList4"
                      :class="{hide_box: handupload_btn}"
                      :on-change="handchange"
                      list-type="picture-card"
                      :on-preview="handPreview"
                      :on-remove="handhandleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传手持身份证照片</div>
                    </el-upload>
                    <el-dialog :visible.sync="handdialogVisible">
                      <img width="100%" :src="handdialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-col>
              </el-row>
            </el-col>

            <!--右侧内容-->
            <el-col :span="16">
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="司机姓名" prop="realname">
                    <el-input
                      clearable
                      v-model="form.realname"
                      placeholder="请输入"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="联系电话" prop="phone">
                    <el-input
                      clearable
                      v-model="form.phone"
                      placeholder="请输入"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="身份证号码" prop="id_number">
                    <el-input
                      clearable
                      v-model="form.id_number"
                      placeholder="请输入身份证号码"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="身份证有效期" prop="id_valid_time">
                    <!--<el-date-picker-->
                    <!--  v-model="form.id_valid_time"-->
                    <!--  type="daterange"-->
                    <!--  value-format="yyyy-MM-dd"-->
                    <!--  range-separator="至"-->
                    <!--  start-placeholder="开始日期"-->
                    <!--  end-placeholder="结束日期">-->
                    <!--</el-date-picker>-->
                    <!--<DatePicker v-model="form.id_valid_time" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                    <el-date-picker
                    unlink-panels
                      v-model="form.id_valid_time"
                      type="date"
                      placeholder="请选择"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="身份证地址" prop="id_address">
                    <el-input
                      clearable
                      v-model="form.id_address"
                      placeholder="身份证地址"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="服务区域" prop="serve_district">
                    <!--:options="options"-->
                    <el-cascader
                      ref="refCascader"
                      :options="city"
                      v-model="form.serve_district"
                      @change="handleChange"></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <!--<el-col :span="12">-->
                <!--  <el-form-item label="驾驶证号码" prop="drivers_number">-->
                <!--    <el-input-->
                <!--      clearable-->
                <!--      v-model="form.drivers_number"-->
                <!--      placeholder="驾驶证号码"/>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :span="12">
                  <el-form-item label="驾驶证有效期" prop="drivers_valid_time">
                    <!--<el-date-picker-->
                    <!--  v-model="form.drivers_valid_time"-->
                    <!--  type="daterange"-->
                    <!--  value-format="yyyy-MM-dd"-->
                    <!--  range-separator="至"-->
                    <!--  start-placeholder="开始日期"-->
                    <!--  end-placeholder="结束日期">-->
                    <!--</el-date-picker>-->
                    <!--<DatePicker v-model="form.drivers_valid_time" @on-change="datechange1" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                    <el-date-picker
                    unlink-panels
                      v-model="form.drivers_valid_time"
                      type="date"
                      placeholder="请选择"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="准驾车型" prop="drivers_type">
                    <el-select
                      clearable
                      v-model="form.drivers_type"
                      placeholder="请选择准驾车型"
                      class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.Quasidrivingtype"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="司机状态" prop="status">
                    <el-radio-group v-model="form.status">
                      <el-radio label="启用" value="0"></el-radio>
                      <el-radio label="禁用" value="-1"></el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <!--<el-col :span="12">-->
                <!--  <el-form-item label="司机类型" prop="driver_type">-->
                <!--    <el-select-->
                <!--      clearable-->
                <!--      v-model="form.driver_type"-->
                <!--      placeholder="请选择"-->
                <!--      class="ele-fluid">-->
                <!--      <el-option label="自营" value="self"/>-->
                <!--      <el-option label="共享" value="share"/>-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :span="12">-->
                <!--  <el-form-item label="服务商" prop="facilitator_id">-->
                <!--    <el-select-->
                <!--      clearable-->
                <!--      @change="selectservice"-->
                <!--      v-model="form.facilitator_id"-->
                <!--      placeholder="请选择"-->
                <!--      class="ele-fluid">-->
                <!--      <el-option-->
                <!--        v-for="(item,index) in service"-->
                <!--        :label="item.name"-->
                <!--        :value="item.value"/>-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
              </el-row>

              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="绑定车辆" prop="vehicle_id">
                    <el-select
                      clearable
                      v-model="form.vehicle_id"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-for="(item,index) in vehicle"
                        :label="item.license_plate_number"
                        :value="item.id"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                
              </el-row>

              <el-row :gutter="15">
                <el-col :span="24">
                  <el-form-item label="服务类型" prop="serve_type">
                    <!--<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>-->
                    <!--<div>-->
                    <!--  <el-checkbox-group v-model="form.serve_type" @change="handleCheckedCitiesChange">-->
                    <!--    <el-checkbox v-for="item in service_type" :label="item" :key="item">{{item.label}}</el-checkbox>-->
                    <!--  </el-checkbox-group>-->
                    <!--</div>-->
                    <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <el-checkbox-group v-model="form.serve_type">
                      <el-checkbox v-for="(item,index) in service_type" :key="index" :label="item.serial_number" @change="selectchange">{{item.label}}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15" v-if="loginUser.rank != 'teamwork'">
                <el-col :span="8">
                  <el-form-item label="薪酬规则" prop="salary_rules">
                    <el-select
                      clearable
                      v-model="form.salary_rules"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-for="(item,index) in remuneration"
                        :label="item.name"
                        :value="item.id"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-button class="button" type="primary" style="background: #7958C7!important;border-color: #7958C7!important;color: #FFFFFF!important;" @click="AddPay()">
                    <!--el-icon-devops 是自己命名的-->
                    <i class="el-icon-tianjia" />
                    <span style="vertical-align: middle">创建薪酬 </span>
                  </el-button>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <!--<el-col :span="12">-->
                <!--  <el-form-item label="渠道客户">-->
                <!--    <el-checkbox v-model="form.checked">拖车救援</el-checkbox>-->
                <!--    <el-checkbox v-model="form.checked1">搭电救援</el-checkbox>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :span="12">
                  <el-form-item label="服务时间" prop="serve_time">
                    <el-time-picker
                      value-format="HH:mm:ss"
                      is-range
                      v-model="form.serve_time"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围">
                    </el-time-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>

          </el-row>

        </el-card>
      </el-form>

      <!--按钮-->
      <div class="card_bottom">
        <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>
        <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')" v-auths="[`${$config.uniquePrefix}yunli:list:put`]">提交</el-button>
      </div>
    </div>


    <el-dialog
      center
      title="创建薪酬"
      :visible.sync="showremuneration"
      width="80%"
      :before-close="handleClose">
      <div>
        <!--薪酬记录组件-->
        <Pay v-if="showPay == true" @showPay="ShowPay"/>
      </div>
    </el-dialog>


  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

//引入的接口
import {
  getcityList,
  getsome_facilitator_vehicle,
  Addself_driver,
  Edutself_driver,
  getservice_type,
  getemolument_list,
  commonfacilitator, some_facilitator_remuneration
} from '@/api/yunli'

//引入薪酬记录
import Pay from '../pay/index.vue'

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // id
    id:Number
  },
  // 组件生效
  components: {Pay},
  // Data数据
  data() {
    return {
      // 标题
      title:'',
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        realname: [
          { required: true, message: '请输入司机姓名', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        id_number:[
          { required: true, message: '身份证号不能为空'},
          {pattern:/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: '不是合法的身份证号！',trigger: 'blur'}
        ],
        id_valid_time: [
          { required: true, message: '请选择身份证有效期', trigger: 'change' },
        ],
        serve_district: [
          { required: true, message: '请选择区域', trigger: 'serve_district' },
        ],
        drivers_number:[
          { required: true, message: '请输入驾驶证号码', trigger: 'blur' },
          { min: 0, max: 18, message: '长度最多输入18个字符', trigger: 'blur' }
        ],
        drivers_valid_time:[
          { required: true, message: '请选择驾驶证有效期', trigger: 'change' },
        ],
        drivers_type:[
          { required: true, message: '请选择准驾车型', trigger: 'change' },
        ],
        status:[
          { required: true, message: '请选择状态', trigger: 'change' }
        ],
        // driver_type:[
        //   { required: true, message: '请选择司机类型', trigger: 'change' }
        // ],
        // facilitator_id: [
        //   { required: true, message: '请选择服务商', trigger: 'change' }
        // ],
        vehicle_id:[
          { required: true, message: '请选择绑定车辆', trigger: 'change' }
        ],
        serve_time:[
          { required: true, message: '请选择服务时间', trigger: 'change' }
        ],
        serve_type: [
          { type: 'array', required: true, message: '请选择服务类型', trigger: 'change' }
        ]
      },
      isUpdate:false,

      // 身份证正面
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],

      //身份证反面
      oppositedialogImageUrl:'',
      oppositedialogVisible:false,
      oppositeupload_btn:false,
      fileList2:[],

      //手持身份证照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList4:[],

      //驾驶证正面
      licensedialogImageUrl:'',
      licensedialogVisible:false,
      licenseupload_btn:false,
      fileList1:[],

      //驾驶证反面
      licenseoppositedialogImageUrl:'',
      licenseoppositedialogVisible:false,
      licenseoppositeupload_btn:false,
      fileList3:[],

      // 全选
      checkAll: false,

      //薪酬记录模块
      showPay:false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },


      //城市列表
      city:[],
      //绑定车辆
      vehicle:[],
      //服务类型
      service_type:[],
      //薪酬
      remuneration:[],
      // 服务商
      service:[],

      //自定义服务区域
      serve_district:[],
      // 服务区域是否修改
      xiugai:false,

      //薪酬
      showremuneration:false,

    }
  },
  // 计算属性 类似于 data 概念
  computed: {
    // 当前登录用户信息
    loginUser () {
      return this.$store.state.admin.userInfo
    },
  },
  // 监控 data 中的数据变化
  watch: {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    console.log('132456')

    if (this.data) {
      this.title = '编辑'
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
      // 赋值一下状态
      if(this.form.status === 0) {
        this.form.status = '启用'
      }else {
        this.form.status = '禁用';
      }
    } else {
      this.title = '新增'
      this.form = {
        serve_type:[],
        status:'启用'
      };
      this.isUpdate = false;

      // 解决一进来就走验证规则问题
      this.$refs['form'].resetFields();
    }

    console.log(this.form);

    //判断是否为编辑 如果是编辑则添加图片
    if(this.isUpdate === true){
      //身份证正面
      if(this.form.identification_photo_front != ''){
        let data = {
          url: this.form.identification_photo_front
        };
        this.upload_btn = true;
        this.fileList.push(data);
      }
      //反面
      if(this.form.identification_photo_reverse != ''){
        let data = {
          url: this.form.identification_photo_reverse
        };
        this.oppositeupload_btn = true;
        this.fileList2.push(data);
      }

      //驾驶证
      if(this.form.driving_license_front != ''){
        let data = {
          url: this.form.driving_license_front
        };
        this.licenseupload_btn = true;
        this.fileList1.push(data);
      }
      //反面
      if(this.form.driving_license_reverse != ''){
        let data = {
          url: this.form.driving_license_reverse
        };
        this.licenseoppositeupload_btn = true;
        this.fileList3.push(data);
      }

      //手持身份证
      if(this.form.hand_photo != ''){
        let data = {
          url: this.form.hand_photo
        };
        this.handupload_btn = true;
        this.fileList4.push(data);
      }

      this.form.serve_district = this.form.city_code;

    }

    //调用获取城市列表
    this.getCity();
    //调用获取所有车辆
    // this.getvehicle();
    //调用获取服务类型
    this.getservice_type();
    //调用获取薪酬
    this.getremuneration();
    // 调用获取服务商列表
    this.getcommonfacilitator();

  },
  // 方法集合
  methods: {
    // 获取服务商列表包含自己
    getcommonfacilitator(){
      commonfacilitator().then(res => {
        // console.log(res)
        this.service = res.data;
        // 默认获取下当前服务商下的车辆
        // let params = {
        //   facilitator_id: this.service[0].value
        // }
        getsome_facilitator_vehicle().then(res => {
          this.vehicle = res.data;
        })
      })
    },

    //选择服务商
    selectservice(e){
      console.log(e);
      // 获取服务商的车辆
      this.getvehicle(e);
    },

    //获取服务商的车辆
    getvehicle(e){
      let params = {
        facilitator_id : e
      }
      getsome_facilitator_vehicle(params).then(res => {
        this.vehicle = res.data;
      })
    },

    //获取服务类型
    getservice_type(){
      getservice_type().then(res => {
        console.log(res)
        this.service_type = res.data;

        //编辑时全选
        // console.log(this.form.serve_type.length)
        // console.log(this.service_type.length)
        if(this.form.serve_type.length == this.service_type.length){
          this.checkAll = true
        }else {
          this.checkAll = false;
        }
      })
    },

    //获取薪酬
    getremuneration(){
      some_facilitator_remuneration().then(res => {
        console.log(res);
        this.remuneration = res.data;
      })
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //身份证正面
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.identification_photo_front = file.data.urls;
    },

    //身份证反面
    oppositePreview(file){
      this.oppositedialogImageUrl = file.url;
      this.oppositedialogVisible = true;
    },
    oppositehandleRemove(file,fileList){
      this.oppositeupload_btn = false;
    },
    oppositechange(){
      this.oppositeupload_btn = true;
    },
    //上传成功
    successUpload1(file,fileList){
      console.log(file);
      console.log(fileList)
      this.oppositedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.identification_photo_reverse = file.data.urls;
    },

    //手持身份证照片
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    //上传成功
    successUpload2(file,fileList){
      console.log(file);
      console.log(fileList)
      this.oppositedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.hand_photo = file.data.urls;
    },



    //驾驶证正面
    licensePreview(file){
      this.licensedialogImageUrl = file.url;
      this.licensedialogVisible = true;
    },
    licensehandleRemove(file,fileList){
      console.log(file, fileList);
      this.licenseupload_btn = false;
    },
    licensechange(){
      this.licenseupload_btn = true;
    },
    //上传成功
    successUpload3(file,fileList){
      console.log(file);
      console.log(fileList)
      this.licensedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.driving_license_front = file.data.urls;
    },

    //驾驶证反面
    licenseoppositePreview(file){
      this.licenseoppositedialogImageUrl = file.url;
      this.licenseoppositedialogVisible = true;
    },
    licenseoppositehandleRemove(file,fileList){
      console.log(file, fileList);
      this.licenseoppositeupload_btn = false;
    },
    licenseoppositechange(){
      this.licenseoppositeupload_btn = true;
    },
    // 上传成功
    successUpload4(file,fileList){
      console.log(file);
      console.log(fileList)
      this.licenseoppositedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.driving_license_reverse = file.data.urls;
    },


    //返回按钮
    returnPage(){
      this.$emit('showEdit',false) ;
    },

    //级联选择器
    handleChange(value) {
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area)
      this.serve_district = area;
      this.xiugai = true;
    },

    //点击创建薪酬
    AddPay(){
      // this.showremuneration = true;
      // this.showPay = true;
      this.$router.push('/humanResources/WorkerSalaryManagement')
    },
    //接收薪酬组件的传值
    ShowPay(value){
      this.showPay = value;
      this.showremuneration = false;
      this.getremuneration();
    },

    //点击服务类型全选
    handleCheckAllChange(){
      if(this.checkAll == true){
        this.service_type.forEach(item => {
          this.form.serve_type.push(item.serial_number)
        })
      }else {
        this.form.serve_type = [];
      }
    },
    // 服务类型选中值
    selectchange(){
      // 如果选择的数组长度等于总共的服务类型长度全选为true
      if(this.form.serve_type.length == this.service_type.length){
        this.checkAll = true
      }else {
        this.checkAll = false;
      }
    },

    // 身份证有效期选择日期
    datechange(e){
      this.form.id_valid_time = e;
    },
    // 驾驶证有效期
    datechange1(e){
      this.form.drivers_valid_time = e;
    },

    // 保存
    save(formName){
      console.log(this.form)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.identification_photo_front == undefined || this.form.identification_photo_front == ''){
            this.$message.error('请上传身份证正面照片');
          }else if(this.form.identification_photo_reverse == undefined || this.form.identification_photo_reverse == ''){
            this.$message.error('请上传身份证反面照片');
          }else if(this.form.driving_license_front == undefined || this.form.driving_license_front == ''){
            this.$message.error('请上传驾驶证正面照片');
          }else if(this.form.driving_license_reverse == undefined || this.form.driving_license_reverse == ''){
            this.$message.error('请上传驾驶证反面照片');
          }else if(this.form.hand_photo == undefined || this.form.hand_photo == ''){
            this.$message.error('请上传手持身份证照片');
          } else {
            if(this.form.status === '启用') {
              this.form.status = 0
            }else {
              this.form.status = -1;
            }
            // 判断是创建还是编辑
            if(this.isUpdate === false){
              this.form.serve_district = this.serve_district;
              //创建
              Addself_driver(this.form).then(res => {
                console.log(res)
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  this.$emit('showEdit',false) ;
                  this.$emit('done');
                } else {
                  this.$message.error(res.msg);
                }
              }).catch(e => {
                this.$message.error(e.msg);
              })
            }else {
              if(this.form.xiugai == true){
                this.form.serve_district = this.serve_district
              }else {
                this.form.serve_district = this.data.serve_district
              }
              //编辑
              Edutself_driver(this.form.id,this.form).then(res => {
                console.log(res)
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  this.$emit('showEdit',false) ;
                  this.$emit('done');
                } else {
                  this.$message.error(res.msg);
                }
              }).catch(e => {
                this.$message.error(e.msg);
              })
            }
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },


    //关闭薪酬
    handleClose(){
      this.showremuneration = false;
    },

  },
}
</script>

<style scoped lang="scss">
  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }

</style>
