<template>
  <div>
    <div class="title">
      <div class="title_t">
        <span>服务商创建</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>
    <div class="bimianchongtu">
      <div style="padding-bottom: 30px" class="fuwushangzhuce">
        <div>
          <div class="fuwushangzhucecontent">
            <!--填写企业信息-->
            <div v-if="status == 0">
              <!--<div v-if="approve_status == 'false'">-->
              <!--  <span style="color: red;">不合格原因: {{form.reason}}</span>-->
              <!--</div>-->
              <el-form
                ref="form"
                :model="form"
                label-width="120px"
                :rules="rules"
                label-position="left"
                size="small "
              >
                <el-row :gutter="15">
                  <el-col :span="8">
                    <el-row :gutter="15">
                      <el-col
                        :span="12"
                        style="display: flex; justify-content: center"
                      >
                        <el-form-item label-width="0">
                          <el-upload
                            :limit="1"
                            accept=".jpg, .png, jpeg"
                            :class="{ hide_box: businessLicenseupload_btn }"
                            :on-change="businessLicensechange"
                            :http-request="upload"
                            :action="action"
                            :file-list="fileList"
                            list-type="picture-card"
                            :on-preview="businessLicensePreview"
                            :on-remove="businessLicensehandleRemove"
                          >
                            <i class="el-icon-plus"></i>
                            <div class="el-upload__tip explain" slot="tip">
                              营业执照（智能识别）
                            </div>
                          </el-upload>
                          <el-dialog
                            :visible.sync="businessLicensedialogVisible"
                            :close-on-click-modal="false"
                            top="5vh"
                          >
                            <img
                              width="100%"
                              :src="businessLicensedialogImageUrl"
                              alt=""
                            />
                          </el-dialog>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :span="12"
                        style="display: flex; justify-content: center"
                      >
                        <el-form-item label-width="0">
                          <el-upload
                            :action="action"
                            accept=".jpg, .png, jpeg"
                            :http-request="successUploadlogo"
                            :limit="1"
                            :file-list="fileListlogo"
                            :class="{ hide_box: businessLicenseupload_btnlogo }"
                            :on-change="changelogo"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreviewlogo"
                            :on-remove="handleRemovelogo"
                          >
                            <i class="el-icon-plus"></i>
                            <div class="el-upload__tip explain" slot="tip">
                              企业logo
                            </div>
                          </el-upload>
                          <el-dialog
                            :visible.sync="businessLicensedialogVisiblelogo"
                          >
                            <img
                              width="100%"
                              :src="businessLicensedialogImageUrllogo"
                              alt=""
                            />
                          </el-dialog>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col
                        :span="12"
                        style="display: flex; justify-content: center"
                      >
                        <el-form-item label-width="0">
                          <el-upload
                            :limit="1"
                            accept=".jpg, .png, jpeg"
                            :class="{ hide_box: upload_btn }"
                            :on-change="change"
                            :http-request="successUpload"
                            :action="action"
                            :file-list="fileList1"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                          >
                            <i class="el-icon-plus"></i>
                            <div class="el-upload__tip explain" slot="tip">
                              法人身份证正面（智能识别）
                            </div>
                          </el-upload>
                          <el-dialog
                            :visible.sync="dialogVisible"
                            :close-on-click-modal="false"
                            top="5vh"
                          >
                            <img width="100%" :src="dialogImageUrl" alt="" />
                          </el-dialog>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :span="12"
                        style="display: flex; justify-content: center"
                      >
                        <el-form-item label-width="0">
                          <el-upload
                            :limit="1"
                            accept=".jpg, .png, jpeg"
                            :class="{ hide_box: oppositeupload_btn }"
                            :on-change="oppositechange"
                            :http-request="successUpload1"
                            :action="action"
                            :file-list="fileList2"
                            list-type="picture-card"
                            :on-preview="oppositePreview"
                            :on-remove="oppositehandleRemove"
                          >
                            <i class="el-icon-plus"></i>
                            <div class="el-upload__tip explain" slot="tip">
                              法人身份证背面
                            </div>
                          </el-upload>
                          <el-dialog
                            :visible.sync="oppositedialogVisible"
                            :close-on-click-modal="false"
                            top="5vh"
                          >
                            <img
                              width="100%"
                              :src="oppositedialogImageUrl"
                              alt=""
                            />
                          </el-dialog>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>

                  <el-col :span="16">
                    <div class="styletitle">企业认证信息</div>
                    <el-row :gutter="15" class="styletop">
                      <el-col :span="12">
                        <el-form-item label="企业名称" prop="company_name">
                          <el-input
                            clearable
                            v-model="form.company_name"
                            placeholder="请输入企业名称"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="统一社会信用代码"
                          label-width="140px"
                          prop="credit_code"
                        >
                          <el-input
                            clearable
                            v-model="form.credit_code"
                            placeholder="请输入社会信用代码"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="12">
                        <el-form-item
                          label="企业注册日期"
                          prop="registration_date"
                        >
                          <el-date-picker
                            v-model="form.registration_date"
                            type="date"
                            value-format="yyyy年MM月dd日"
                            placeholder="选择日期"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-row :gutter="15" v-if="isCity === true">
                        <el-col :span="12">
                          <el-form-item label="省市区" prop="area_city">
                            <el-cascader
                            ref="refCascader"
                              v-model="form.area_city"
                              :options="city"
                               @change="citychange"
                              clearable
                            ></el-cascader>
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-col :span="12">
                        <el-form-item label="注册地址" prop="detailed_address">
                          <div style="display: flex; align-items: center">
                            <!--<el-cascader-->
                            <!--    ref="refCascader"-->
                            <!--    :options="city"-->
                            <!--    v-model="form.registration_place"></el-cascader>-->
                            <!--<el-input-->
                            <!--    clearable-->
                            <!--    v-model="form.detailed_address"-->
                            <!--    placeholder="请输入详细地址"/>-->
                            <div
                              class="xuanzedidianyangshi"
                              @click="opensiteselection(0)"
                            >
                              <span v-if="form.detailed_address == ''"
                                >请选择地址</span
                              >
                              <el-tooltip
                                class="item"
                                effect="dark"
                                :content="form.detailed_address"
                                placement="top-start"
                              >
                                <div class="didian">
                                  {{ form.detailed_address }}
                                </div>
                              </el-tooltip>
                              <i class="el-icon-location-information"></i>
                            </div>
                          </div>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="12">
                        <el-form-item label="法定代表人" prop="legal_person">
                          <el-input
                            clearable
                            v-model="form.legal_person"
                            placeholder="请输入法定代表人"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="注册资本"
                          prop="registered_capital"
                        >
                          <el-input
                            clearable
                            v-model="form.registered_capital"
                            placeholder="请输入注册资本"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="12">
                        <el-form-item
                          label="法定代表人身份证"
                          label-width="140px"
                          prop="corporate_identity_card"
                        >
                          <el-input
                            clearable
                            v-model="form.corporate_identity_card"
                            placeholder="请输入法定代表人身份证"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="认证状态" prop="approve_status">
                          <el-select
                            v-model="form.approve_status"
                            clearable
                            placeholder="请选择"
                          >
                            <el-option label="未通过" :value="-1"></el-option>
                            <el-option label="待认证" :value="0"></el-option>
                            <el-option label="认证通过" :value="1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <div class="styletitle">企业基本信息</div>
                    <el-row :gutter="15" class="styletop">
                      <el-col :span="12">
                        <el-form-item
                          label="企业简称"
                          prop="facilitator_abbreviation"
                        >
                          <el-input
                            clearable
                            v-model="form.facilitator_abbreviation"
                            placeholder="请输入企业简称"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="企业客服电话"
                          label-width=""
                          prop="service_phone"
                        >
                          <el-input
                            clearable
                            v-model="form.service_phone"
                            placeholder="请输入客服电话"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="12">
                        <el-form-item label="调度负责人" prop="dispatch_name">
                          <el-input
                            clearable
                            v-model="form.dispatch_name"
                            placeholder="请输入调度负责人"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item
                          label="调度负责人电话"
                          label-width="122px"
                          prop="dispatch_phone"
                        >
                          <el-input
                            clearable
                            v-model="form.dispatch_phone"
                            placeholder="请输入客服电话"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <!-- v4版本 -->
                    <div>
                      <div class="styletitle">企业收款信息</div>
                      <el-row :gutter="15" class="styletop">
                        <el-col :span="12">
                          <el-form-item
                            label="账户名称"
                            prop="collection_account_name"
                          >
                            <el-input
                              clearable
                              v-model="form.collection_account_name"
                              placeholder="请输入账户名称"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item
                            label="收款账号"
                            label-width=""
                            prop="collection_account_number"
                          >
                            <el-input
                              clearable
                              v-model="form.collection_account_number"
                              placeholder="请输入收款账号"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="15" class="styletop">
                        <el-col :span="12">
                          <el-form-item
                            label="开户行"
                            prop="collection_opening_bank"
                          >
                            <el-input
                              clearable
                              v-model="form.collection_opening_bank"
                              placeholder="请输入开户行"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item
                            label="收款账户联行号"
                            label-width="140px"
                            prop="collection_bank_code"
                          >
                            <el-input
                              clearable
                              v-model="form.collection_bank_code"
                              placeholder="请输入收款账户联行号"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>

                    <!--修改信息时不显示账号信息-->
                    <div v-if="zhanghaoinfo == true">
                      <div class="styletitle">账号信息</div>
                      <el-row :gutter="15" class="styletop">
                        <el-col :span="12">
                          <el-form-item label="系统管理员" prop="realname">
                            <el-input
                              clearable
                              v-model="form.realname"
                              placeholder="请输入系统管理员"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="管理员手机号" prop="phone">
                            <el-input
                              clearable
                              v-model="form.phone"
                              placeholder="请输入管理员手机号用于登录"
                            >
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="15">
                        <el-col :span="12">
                          <el-form-item label="登录密码" prop="password">
                            <el-input
                              v-model="form.password"
                              placeholder="请输入登录密码"
                              show-password
                            />
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="启用状态" prop="status">
                            <el-select
                              v-model="form.status"
                              clearable
                              placeholder="请选择"
                            >
                              <el-option label="启用" :value="0"></el-option>
                              <el-option label="禁用" :value="1"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="15">
                        <el-col :span="12">
                          <el-form-item label="开放合作" prop="is_open_coop">
                            <el-select
                              v-model="form.is_open_coop"
                              clearable
                              placeholder="请选择"
                            >
                              <el-option label="是" :value="0"></el-option>
                              <el-option label="否" :value="1"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
              <!--按钮-->
              <div class="tijiaostykle">
                <el-button style="width: 200px !important" @click="quxiao"
                  >返回</el-button
                >
                <el-button
                  type="warning"
                  style="width: 200px !important; margin-left: 20px"
                  @click="throttledInputHandler"
                  >保存</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        center
        :close-on-click-modal="false"
        v-if="dialogVisible1 == true"
        title="选择地址"
        top="5vh"
        :visible.sync="dialogVisible1"
        width="40%"
        :before-close="handleClose"
      >
        <div>
          <!--自定义高德选点组件-->
          <AMapLoadersiteselection
            v-if="showAMapLoadersiteselection == true"
            :data="origin"
            @location="getlocation"
          />
        </div>
      </el-dialog>

      <el-dialog
        center
        :close-on-click-modal="false"
        v-if="showsuccess == true"
        title="提示"
        top="5vh"
        :visible.sync="showsuccess"
        width="30%"
        :before-close="Closeshowsuccess"
      >
        <div>
          <div class="successtitle">
            {{ successtitle }}
          </div>
          <div style="display: flex; justify-content: center">
            <div>
              <div class="successtitle1">请记住以下信息</div>
              <div class="successtitle2">
                您的管理员登录手机号：{{ zhanghao }}
              </div>
              <div class="successtitle2">您的管理员登录密码：{{ mima }}</div>
            </div>
          </div>
          <div class="successbtn">
            <el-button @click="Closeshowsuccess">返回</el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        center
        :close-on-click-modal="false"
        v-if="showerr == true"
        title="提示"
        top="5vh"
        :visible.sync="showerr"
        width="30%"
        :before-close="Closeerr"
      >
        <div>
          <div class="successtitle">您的企业信息认证失败</div>
          <div>
            <div>
              <div class="successtitle2">认证结果：{{ result }}</div>
              <div class="successtitle3">是否提交进入系统人工审核</div>
              <div class="successtitle2">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入备注"
                  v-model="form.remark"
                >
                </el-input>
              </div>
            </div>
          </div>
          <div class="successbtn">
            <el-button @click="Closeerr">返回修改信息</el-button>
            <el-button type="primary" @click="jinru"
              >提交进入系统人工审核</el-button
            >
          </div>
        </div>
      </el-dialog>

      <el-dialog
        center
        :close-on-click-modal="false"
        v-if="showartificial == true"
        title="提示"
        top="5vh"
        :visible.sync="showartificial"
        width="30%"
        :before-close="Closeartificial"
      >
        <div>
          <div class="successtitle">
            {{ successtitle }}
          </div>
          <div>
            <div>
              <div class="successtitle1">请记住以下信息</div>
              <div class="successtitle2">
                您的管理员登录手机号：{{ zhanghao }}
              </div>
              <div class="successtitle2">您的管理员登录密码：{{ mima }}</div>
            </div>
          </div>
          <div class="successbtn">
            <el-button type="primary" @click="queryjindu"
              >查看审核进度</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 获取配置
import setting from "@/config/setting";
import { getCookies } from "@/utils/cookies";
import Config from "@/config";

// 引入计价公式组件
// import Pricingformula from './components/Pricingformula.vue'
//引入服务项目配置
// import projectConfiguration from './components/projectConfiguration.vue'

//引入的接口
// 引入服务商注册接口
import {
  getfind_register,
  ocr,
  ocrocrRecognizeIdcard,
  verify_firm,
  chuangjiang,
} from "@/api/serviceregister";
import { throttle } from "throttle-debounce";
import { getplatform_verify_code, getplatform_verify } from "@/api/account";
// 用来请求公共
import axios from "axios";
//引入自定义高德地图选点组件
import AMapLoadersiteselection from "@/components/AMapLoadersiteselection/index.vue";
import Vue from "vue";

export default {
  components: {
    AMapLoadersiteselection,
  },
  data() {
    return {
      value_address:"",
      form: {
        company_name: "",
        credit_code: "",
        registered_capital: "",
        registration_date: "",
        detailed_address: "",
        legal_person: "",
        corporate_identity_card: "",
        // longitude:0,
        // latitude:0,
        phone: "",
        is_auto: "",
        remark: "",
        approve_status: "",
        area_city:[],
      },
      isCity:false,
      area:[],
      // 验证规则
      rules: {
        registered_capital: [
          { required: true, message: "请输入注册资本", trigger: "blur" },
        ],
        company_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        approve_status: [
          { required: true, message: "请选择认证状态", trigger: "change" },
        ],
        credit_code: [
          { required: true, message: "请输入企业信用代码", trigger: "blur" },
        ],
        registration_date: [
          { required: true, message: "请输入企业注册日期", trigger: "blur" },
        ],
        detailed_address: [
          { required: true, message: "请输入企业注册地址", trigger: "blur" },
        ],
        corporate_identity_card: [
          {
            required: true,
            message: "请输入法定代表人身份证",
            trigger: "blur",
          },
        ],
        legal_person: [
          { required: true, message: "请输入法定代表人", trigger: "blur" },
        ],
        realname: [
          { required: true, message: "请输入司机姓名", trigger: "blur" },
        ],
        is_open_coop: [
          { required: true, message: "请选择是否开放合作", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        id_number: [
          { required: true, message: "身份证号不能为空" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "不是合法的身份证号！",
            trigger: "blur",
          },
        ],
        id_valid_time: [
          { required: true, message: "请选择身份证有效期", trigger: "change" },
        ],
        serve_district: [
          { required: true, message: "请选择区域", trigger: "serve_district" },
        ],
        drivers_number: [
          { required: true, message: "请输入驾驶证号码", trigger: "blur" },
        ],
        drivers_valid_time: [
          { required: true, message: "请选择驾驶证有效期", trigger: "change" },
        ],
        drivers_type: [
          { required: true, message: "请选择准驾车型", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        // driver_type:[
        //   { required: true, message: '请选择司机类型', trigger: 'change' }
        // ],
        dispatch_name: [
          { required: true, message: "请选择调度负责人", trigger: "blur" },
        ],
        dispatch_phone: [
          { required: true, message: "请选择调度负责人电话", trigger: "blur" },
        ],
        facilitator_id: [
          { required: true, message: "请选择服务商", trigger: "change" },
        ],
        facilitator_abbreviation: [
          { required: true, message: "请填写简称", trigger: "change" },
        ],
        service_phone: [
          { required: true, message: "请填写企业客服电话", trigger: "change" },
        ],
        vehicle_id: [
          { required: true, message: "请选择绑定车辆", trigger: "change" },
        ],
        serve_time: [
          { required: true, message: "请选择服务时间", trigger: "change" },
        ],
        collection_account_name: [
          { required: true, message: "请填写账户名称", trigger: "blur" },
        ],
        collection_account_number: [
          { required: true, message: "请填写收款账号", trigger: "blur" },
        ],
        collection_opening_bank: [
          { required: true, message: "请填写开户行", trigger: "blur" },
        ],
        collection_bank_code: [
          { required: true, message: "请填写收款账户联行号", trigger: "blur" },
        ],
        area_city:[
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      version: setting.version,
      // 上传接口
      // action: setting.commonurl + '/upload/business_license',
      action: "",
      // 上传请求头部
      // headers:{
      //   'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      // },

      // 身份证正面
      dialogImageUrl: "",
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList1: [],

      //身份证反面
      oppositedialogImageUrl: "",
      oppositedialogVisible: false,
      oppositeupload_btn: false,
      fileList2: [],

      //营业执照
      businessLicenseupload_btn: false,
      businessLicensedialogVisible: false,
      businessLicensedialogImageUrl: "",
      fileList: [],

      //logo
      businessLicenseupload_btnlogo: false,
      businessLicensedialogVisiblelogo: false,
      businessLicensedialogImageUrllogo: "",
      fileListlogo: [],

      //步骤
      status: 0,

      //计价公式模块
      showPricingformula: false,

      //服务项目配置
      showpeizhi: false,

      //城市列表
      city: [],
      xiugai: false,
      registration_place: [],

      appid: "",
      approve_status: 0,

      value: [],

      //选择地址
      dialogVisible1: false,
      //
      showAMapLoadersiteselection: false,

      //地址
      addreas: "",
      location: [],

      // 获取验证码
      codeText: "获取验证码",
      codeTime: 0,

      // 认证成功
      showsuccess: false,
      // 认证失败
      showerr: false,
      // 人工审核弹窗
      showartificial: false,
      // 认证结果
      result: "",

      successtitle: "",
      zhanghao: "",
      mima: "",

      //第二步的审核不合格原因
      buhegereason: "",

      //字典
      dict: {
        type: {},
      },

      //是否展示结算方式
      isShow: false,

      //不显示账号信息
      zhanghaoinfo: true,
    };
  },

  mounted() {
    //判断下是否展示结算方式
    console.log(this.$route.query);
    if (this.$route.query.name == "add") {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

    // 判断是否已经注册
    if (this.$route.query.authentication != undefined) {
      this.appid = this.$route.query.appid;
      // 调用查询进度
      this.queryjindu();
      // this.getquery_register();
    }

    // 初始化dict
    this.$store.getters.dict.forEach((item) => {
      if (item.name === "运力字典") {
        item.children.forEach((res) => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        });
      }
    });

    // 获取城市
    this.getCity();
  },

  methods: {
    // 点击获取验证码
    getCode() {
      // 定义手机号正则表达式
      const phoneRegex = /^1[345789]\d{9}$/;
      // 使用test方法检查手机号是否符合格式
      // if (phoneRegex.test(this.form.phone)) {
      // 使用test方法检查手机号是否符合格式
      if (this.form.phone.length == 11) {
        // 获取平台验证码key接口
        getplatform_verify_code().then((res) => {
          let data = {
            key: res.data.key,
            phone: this.form.phone,
          };
          // 获取验证码接口
          getplatform_verify(data)
            .then((res) => {
              if (res.code === 200) {
                //弹出提示框
                if (this.codeText === "获取验证码" && this.codeTime === 0) {
                  this.$message({
                    message: "验证码已发送",
                    type: "success",
                  });
                }
                if (this.codeTime > 0) {
                  this.$message({
                    message: "不能重复获取",
                    type: "warning",
                  });
                  return;
                } else {
                  this.codeTime = 30;
                  let timer = setInterval(() => {
                    this.codeTime--;
                    if (this.codeTime < 1) {
                      clearInterval(timer);
                      this.codeTime = 0;
                    }
                  }, 1000);
                }
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        this.$message.error("手机号格式错误");
        return false;
      }
    },

    //选择位置
    opensiteselection(number) {
      // 用于判断是起点还是终点
      this.origin = number;
      this.dialogVisible1 = true;
      //this.showAMapLoadersiteselection = true;
      this.showAMapLoadersiteselection = false;
      this.$nextTick(() => {
        this.showAMapLoadersiteselection = true;
      });
    },
    //获取选择地址后的数据
    getlocation(value) {
      // console.log(value)
      // 救援起点的位置
      if (value.drag == true) {
        this.form.detailed_address = value.addreas;
      } else {
        this.form.detailed_address =
          (value.pname != undefined ? value.pname : "") +
          (value.cityname != undefined ? value.cityname : "") +
          (value.adname != undefined ? value.adname : "") +
          (value.name != undefined ? value.name : "");
      }
      this.location = [];
      // 救援起点的经度纬度
      this.location[0] = value.location.lng;
      this.location[1] = value.location.lat;
      // this.form.longitude = this.location[0];
      // this.form.latitude = this.location[1];
      this.dialogVisible1 = false;
    },

    //关闭
    handleClose() {
      this.dialogVisible1 = false;
    },

    // 获取城市列表
    getCity() {
      axios({
        method: "GET",
        url: setting.commonurl + "/city_list",
      }).then((res) => {
        // console.log(res)
        this.city = res.data.data;
      });
    },

    //查询服务商注册入住信息接口
    getquery_register() {
      let params = {
        appid: this.appid,
      };
    },

    //查询服务商注册入住接口
    getInfo() {
      let params = {
        appid: this.appid,
      };
      getfind_register(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          if (res.data.approve_status == 0 || res.data.approve_status == -1) {
            // 审核中还是审核不通过 0是审核中 -1是审核不通过
            this.approve_status = res.data.approve_status;
            // 赋值下不合格原因
            this.buhegereason = res.data.reason;
            // 跳转到审核步骤
            this.status = 1;
          }
        }
      });
    },

    //返回上级页面
    returnPage() {
      this.$emit("adds", false);
    },

    //身份证正面
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change() {
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      axios.post(setting.commonurl + "/upload/idCard", formData).then((res) => {
        this.dialogImageUrl = res.data.data.urls;
        // 上传成功后的列表
        this.form.legal_person_identify_front = res.data.data.urls;
        // ocr身份证 识别
        let data = {
          url: res.data.data.urls,
        };

        axios
          .post(setting.commonurl + "/ocr/recognize_idcard", data)
          .then((respase) => {
            if (respase.data.code == 200) {
              this.$message.success(res.data.msg);
              console.log(respase.data.data.face.data.name, "名字");
              this.form.legal_person = respase.data.data.face.data.name;
              this.form.corporate_identity_card =
                respase.data.data.face.data.idNumber;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },

    //logo
    handleRemovelogo(file, fileList) {
      console.log(file, fileList);
      this.businessLicenseupload_btnlogo = false;
    },
    handlePictureCardPreviewlogo(file) {
      this.businessLicensedialogImageUrllogo = file.url;
      this.dialogVisible = true;
    },
    changelogo() {
      // 上传成功后，隐藏上传按钮
      this.businessLicenseupload_btnlogo = true;
    },
    //上传成功
    successUploadlogo(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      axios.post(setting.commonurl + "/upload/logo", formData).then((res) => {
        // 上传成功后的列表
        this.form.logo = res.data.data.urls;
      });
    },

    //身份证反面
    oppositePreview(file) {
      this.oppositedialogImageUrl = file.url;
      this.oppositedialogVisible = true;
    },
    oppositehandleRemove(file, fileList) {
      this.oppositeupload_btn = false;
    },
    oppositechange() {
      this.oppositeupload_btn = true;
    },
    //上传成功
    successUpload1(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      axios.post(setting.commonurl + "/upload/idCard", formData).then((res) => {
        // 上传成功后的列表
        this.form.legal_person_identify_reverse = res.data.data.urls;
      });
    },

    // 营业执照
    businessLicensePreview(file) {
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file, fileList) {
      // console.log(file, fileList);
      this.businessLicenseupload_btn = false;
    },
    businessLicensechange() {
      this.businessLicenseupload_btn = true;
    },
    upload(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      axios
        .post(setting.commonurl + "/upload/business_license", formData)
        .then((res) => {
          // 上传成功后的列表
          this.form.business_license = res.data.data.urls;
          // ocr营业执照 识别
          let data = {
            url: res.data.data.urls,
          };
          axios
            .post(setting.commonurl + "/ocr/business_license", data)
            .then((respase) => {
              console.log(respase.data.data, "res");
              if (respase.data.code == 200) {
                this.$message.success(respase.data.msg);
                this.form.company_name = respase.data.data.companyName;
                this.form.credit_code = respase.data.data.creditCode;
                this.form.registered_capital =
                  respase.data.data.registeredCapital;
                const year = respase.data.data.validFromDate.slice(0, 4);
                const month = (
                  "0" +
                  parseInt(respase.data.data.validFromDate.slice(4, 6), 10)
                ).slice(-2); // 月份减1，并确保是两位数
                const day = respase.data.data.validFromDate.slice(6, 8);
                this.form.registration_date = `${year}年${month}月${day}日`;
                // this.form.registration_date =
                //   respase.data.data.RegistrationDate;
                  var pattern = /(省|自治区|北京市|天津市|上海市|重庆市|新疆|西藏|宁夏|内蒙古|广西|香港|澳门)(.*?)市(.*?)区|县|镇/;
            console.log(pattern.test(respase.data.businessAddress))
            if(pattern.test(respase.data.data.businessAddress) === false){
              this.isCity = true;
              this.form.detailed_address = respase.data.data.businessAddress;
              this.area = [];
            }else {
              this.isCity = false;
              this.form.detailed_address = respase.data.data.businessAddress;
            }
                // this.form.detailed_address = respase.data.data.businessAddress;
                // console.log(this.form.company_name);
              } else {
                this.$message.error(respase.data.msg);
              }
            });
          // ocr(data).then(respase => {
          //   if(respase.code == 200){
          //     this.$message.success(respase.msg);
          //     this.form.company_name = respase.data.companyName;
          //     this.form.credit_code = respase.data.creditCode;
          //     this.form.registered_capital = respase.data.registeredCapital;
          //     this.form.registration_date = respase.data.RegistrationDate;
          //     this.form.detailed_address = respase.data.businessAddress;
          //   }else {
          //     this.$message.error(respase.msg);
          //   }
          // })
        });
    },

    //点击取消
    quxiao() {
      // 返回上级页面
      this.returnPage();
    },
    throttledInputHandler: throttle(200, function () {
      // 你的处理逻辑
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.area_city = this.area;
          chuangjiang(this.form).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.$emit("adds", false);
            } else if (res.code == 0) {
              this.$message.error(res.message);
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });

      // 这里可以调用其他函数或执行其他操作
    }), // 假设我
    //认证审核成功 关闭
    Closeshowsuccess() {
      this.showsuccess = false;
    },

    //认证审核失败 关闭
    Closeerr() {
      // 置空人工备注
      this.form.remark = "";
      this.showerr = false;
    },

    //进入系统人工审核
    jinru() {
      // 调用注册服务商接口
      this.form.appid = this.appid;
      // 判断下时注册还是新增合作服务商接口
      if (this.isShow == true) {
      } else {
      }
    },

    // 人工审核 关闭
    Closeartificial() {
      // 隐藏成功进入人工审核弹窗
      this.showartificial = false;
    },

    // 查看审核进度
    queryjindu() {
      // 隐藏成功进入人工审核弹窗
      this.showartificial = false;
      // 调用查询服务商是否入住接口
      this.getInfo();
    },

    //等待系统审核
    fanhui() {
      this.$router.go(-1);
      // this.status = 0;
    },
    // 返回修改信息
    xiugairenzhengxin() {
      this.getquery_register();
    },

    //点击初始化配置
    chushihua() {
      this.status = 2;
    },

    //点击服务区域及价格配置
    jiagepeizhi() {
      this.showPricingformula = true;
    },
    //接收子组件传递的值
    jijia(value) {
      this.showPricingformula = value;
    },

    //点击服务项目配置
    peizhi() {
      this.showpeizhi = true;
    },
    //接收子组件传递的值
    xiangmu(value) {
      this.showpeizhi = value;
    },
    citychange(value){
      console.log(value)
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = areaTextArr[0];
      let city = areaTextArr[1];
      let district = areaTextArr[2];
      area.push(province,city,district);
      console.log(area)
      this.area = area;
    },
  },
};
</script>

<style scoped lang="scss">
.bimianchongtu {
  background: #ffffff;
  .fuwushangzhuce {
    .fuwushangzhucecontent {
      width: 95%;
      margin: auto;
      margin-top: 15px;
      background: #ffffff;
      padding: 20px 20px 10px 20px;
    }

    .hide_box /deep/ .el-upload--picture-card {
      display: none;
    }

    .chushihuabg {
      width: 400px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 30px 20px 30px 20px;
      margin: auto;
      cursor: pointer;
    }
    .chushihuabg1 {
      width: 400px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 30px 20px 30px 20px;
      margin: auto;
      cursor: pointer;
    }

    .buzhou {
      width: 95%;
      margin: auto;
      margin-top: 20px;
    }
    .yangshi {
      //background: #FF9B05;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      cursor: pointer;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .sanjiao {
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-right: 20px solid transparent;
      border-left: 20px solid #ff9b05;
      border-bottom: 20px solid transparent;
    }
    .yangshi2 {
      //background: #E4E5E9;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #242832;
      cursor: pointer;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .yangshi22 {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      cursor: pointer;

      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .yangshi3 {
      //background: #E4E5E9;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #242832;
      cursor: pointer;

      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .yangshi33 {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      cursor: pointer;

      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    //选择地点样式
    .xuanzedidianyangshi {
      border-radius: 5px;
      -webkit-appearance: none;
      border: 1px solid var(--border-color-base);
      box-sizing: border-box;
      color: var(--color-text-regular);
      font-size: inherit;
      height: 33px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .didian {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }

  .explain {
    text-align: center;
  }
  .styletitle {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    font-size: 20px;
  }
  .styletop {
    margin-top: 15px;
  }
  .tijiaostykle {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 30px;
  }

  .successtitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  .successtitle1 {
    font-size: 16px;
  }
  .successbtn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .successtitle3 {
    margin-bottom: 15px;
  }
}
</style>
