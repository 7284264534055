<template>
  <div>
    <div class="title">
      <div class="title_t">
        <span>服务商认证审核</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>
    <el-form ref="form" :model="form" label-width="160px">
      <!--认证信息-->
      <el-card shadow="never">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">认证信息</span>
          <!--<img src="@/assets/images/home/yunli/yirenzheng.png" alt="" style="width: 96px;height: 31px;">-->
        </div>
        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="4">
            <img :src="form.business_license" alt="" style="width: 230px;height: 246px;">
          </el-col>
          <el-col :span="20">
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="企业名称">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.company_name"
                    placeholder="请输入企业名称"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="信用代码">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.credit_code"
                    placeholder="请输入信用代码"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="注册日期">
                  <el-date-picker
                    :disabled="true"
                    v-model="form.registration_date"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册资本">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.registered_capital"
                    placeholder="请输入注册资本 "/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form-item label="注册地址">
                  <el-row :gutter="15">
                    <el-col :span="4">
                      <el-cascader
                        :disabled="true"
                        ref="refCascader"
                        :options="city"
                        v-model="form.registration_place_code"
                      ></el-cascader>
                    </el-col>
                    <el-col :span="18">
                      <el-input
                        :disabled="true"
                        clearable
                        v-model="form.detailed_address"
                        placeholder="请输入详细地址 "/>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法人姓名">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.legal_person"
                    placeholder="请输入法人姓名"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法人身份证">
                  <el-input
                    :disabled="true"
                    clearable
                    v-model="form.corporate_identity_card"
                    placeholder="请输入法人身份证 "/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法人身份证正面">
                  <img :src="form.legal_person_identify_front" alt="" style="width: 210px;height: 140px;">
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法人身份证反面">
                  <img :src="form.legal_person_identify_reverse" alt="" style="width: 210px;height: 140px;">
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-card>

      <!--审核不通过理由-->
      <el-card shadow="never">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;">审核不通过理由</span>
        </div>
        <div style="margin-top: 15px;margin-bottom: 15px;">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8}"
            placeholder="请输入审核不通过的理由"
            v-model="cause">
          </el-input>
        </div>

      </el-card>

    </el-form>

    <!--按钮-->
    <div class="card_bottom">
      <el-button style="width: 240px !important;padding-top:15px;padding-bottom: 15px; background: #F54E71!important;border-color: #F54E71!important;color: #FFFFFF!important;" @click="Failtheaudit">审核不通过</el-button>
      <el-button style="width: 240px !important;padding-top:15px;padding-bottom: 15px;background: #36DD9E!important;border-color: #36DD9E!important;color: #FFFFFF!important;" @click="passtheaudit">审核通过</el-button>
    </div>

  </div>
</template>

<script>
import {getcityList,auditcheck} from '@/api/yunli'
export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // id
    id:Number
  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      //城市列表
      city:[],

      cause:'',

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getCity();
  },
  // 方法集合
  methods: {
    returnPage(){
      this.$emit('showtoexamine',false) ;
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //审核通过
    passtheaudit(){
      let where = {
        approve_status: 1,
      }
      auditcheck(this.form.id,where).then(res => {
        console.log(res);
        if(res.code === 200){
          this.$message.success(res.msg);
          this.$emit('showtoexamine',false) ;
          this.$emit('dome');
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.success(e.msg);
      })
    },

    //审核不通过
    Failtheaudit(){
      if(this.cause === ''){
        this.$message.error('请输入审核不通过理由')
      }else {
        let where = {
          approve_status: -1,
          reason: this.cause
        }
        auditcheck(this.form.id,where).then(res => {
          console.log(res);
          if(res.code === 200){
            this.$message.success(res.msg);
            this.$emit('showtoexamine',false) ;
            this.$emit('dome');
          }else {
            this.$message.success(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg)
        })
      }

    },

  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
